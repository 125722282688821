<template>
  <div class="payContainer">

    <!-- <van-button @click="pay()">
      bbb
    </van-button>
 -->

    <div class="imgArea">
      <!-- 支付信息 -->
      <div class="time" style="display: flex; align-items: center; justify-content: center;">支付剩余时间:<van-count-down style="margin-left: 5px;" :time="showTime" /></div>
      <div class="money" style="font-size: 30px;font-weight: bold; margin-top: 20px;">￥<span>{{ realAmount }}</span></div>
    </div>
    <div class="backBtn">
      <van-button type="primary" size="large" @click="goPay"
        >立即支付</van-button
      >
    </div>
  </div>
</template>

<script>
// import { timestamp } from "@/api/commonApi.js";

export default {
  data() {
    return {
      showTime: 30 * 60 * 60 * 1000,
      realAmount: 60,
      payInfo: {},
      data: {},
    };
  },
  created() {
    // this.getPayInfo();
  },
  methods:{
    getPayInfo(){
      接口-拿信息
      // this.payInfo = {
      //   realAmount: 60,
      //   expireDate: '',
      // };
      // timestamp()
      //   .then((response) => {
      //     if (response.code == 200) {
      //       const date = this.payInfo.expireDate.replace(/-/g,'/');
      //       this.showTime = new Date(date).getTime() - response.timestamp;//13位时间戳 1719555817059
      //     } else {

      //     }
      //   })
      //   .catch((err) => {
      //   });
    },
    goPay(){
      console.log("aaa")
      // const param = {
      //   fareId:"63", // --餐次编号
      //   weakDay:"2024-07-02", 
      //   fareName:"早餐", // --餐次名称
      //   path:"第一人民医院3栋2楼2001", // --配送地址
      //   phone:"15800000000", // --联系电话
      //   name:"张三", //--姓名
      //   sumPrice:"34", //--总价
      //   dishDetailList:[
      //     {
      //       dishId:"17", //-菜品编号
      //       dishName:"八宝粥",  //--菜品名称
      //       dishPrice:"6.8", //--菜品价格
      //       dishCount:"5",  //--菜品数量
      //       comboType:"2",  //--是否为套餐 1-是2-否
      //       comboDay:""  //--套餐天数
      //     }
      //   ]
      // }
      // this.$http
      //   .postAction("/order/preOrder", param)
      //   .then((res) => {
      //     if (res.code == 200) {
      //       this.data = res;
      //     }
      //   })
      //   .catch((e) => {
      //     console.log("aaa e:",e)
      //   });
      const res = {
        code:200,
        msg: "操作成功",
        data: {
          path: "/pages/index/index",
          bizSts: "02",
          rjctRsn:null,
          extraData: {
            codeUrl: "D07293be6586be3568894706e22a5d3b845d4dd92879cb8a6caabf84edff824996a"
          },
          appId: "wxfff79b8ebc947748",
          bizStsDesp: "交易成功",
          txId: "0363439",
          msgId: "4C2024070225045749",
          rjctRsnCd: null,
        }
      }
      
      window.wx.miniProgram.navigateTo({
        url: `/pages/pay/mppay?payParam=${encodeURIComponent(JSON.stringify(res))}`
      })
    },
  },
};
</script>

<style lang="less" scoped>
.payContainer{
  background: #ffffff;
}
.imgArea{
    width: 98%;
    text-align: center;
    margin-top: 100px;
}
.backBtn {
  width: 21.4375rem;
  position: fixed;
  bottom: 0;
  padding: 2rem 1rem;
}
</style>
